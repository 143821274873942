.clean {
  height: 29px; }
#metall {
  width: 245px; }
/* parallax */
.parall_container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .parallax-layer {
    position: absolute;
    z-index: 10;
    img {
      width: 100%; } }
  .layer1,.layer2,.layer3,.layer4,.layer5,.layer6,.layer7,.layer8,.layer9,.layer10,.layer11 {
    position: absolute; }
  .layer1 {
    right: 460px;
    top: -40px;
    img {
      z-index: 9; } }
  .layer2 {
    left: -5px;
    top: -5px; }
  .layer3 {
    bottom: 330px;
    right: 620px;
    z-index: 6; }
  .layer4 {
    right: 530px;
    top: -70px; }
  .layer5 {
    right: 405px;
    bottom: 385px; }

  .layer6 {
    left: 350px;
    top: -70px; }
  .layer7 {
    top: -70px;
    left: -4px;
    img {
      z-index: 10; } }

  .layer8 {
    left: -25px;
    bottom: 450px; }
  .layer9 {
    right: 200px;
    bottom: 530px; }
  .layer10 {
    right: 630px;
    bottom: 345px; }
  .layer11 {
    bottom: 385px;
    left: 240px; } }






/* fix styles */
.icon {
  display: block; }
* {
  outline: none !important; }
a:focus {
  text-decoration: none !important;
  color: #000; }
.check_group {
  font-size: 12px;
  cursor: pointer;
  margin-top: 5px;
  -moz-user-select: none;
  p {
    letter-spacing: 0px;
    width: 115%;
    a {
      color: #fff;
      &:focus {
        color: #fff; } } } }
body {
  padding: 0 !important;
  margin: 0; }
.hiddenInput {
  display: none !important; }
/* fix file form */
.icon-s-file {
  cursor: pointer; }
.link_file {
  cursor: pointer; }
.file_group input {
  display: none !important; }
/* fix slider */
.owl-nav {
  height: 1px;
  position: absolute;
  width: 100%;
  top: 40%; }
.owl-prev {
  float: left;
  margin-left: -50px; }
.owl-next {
  float: right;
  margin-right: -50px; }
.more_colors {
  display: none; }

/* ------------------------------------------------ time color fix */
.color {
  width: 20px;
  height: 20px;
  margin: 4px; }
.color_block .item,
.more_colors .item {
  width: 30px;
  height: 30px;
  float: left;
  cursor: pointer;
  border: 1px solid transparent;
  transition: .5s; }
.color_block .item.selected,
.more_colors .item.selected {
  border: 1px solid #aaa; }
.color_block.row, .more_colors.row {
  margin: 0 auto; }






/* main style */
body {
  font-family: "Lora Regular";
  font-size: 14px;
  color: #000000; }
header {
  background-color: #191a1f;
  height: 70px;
  padding: 0 5px;
  z-index: 20;
  position: relative;
  .left_txt {
    color: #fff;
    font-family: Century Gothic;
    text-transform: uppercase;
    padding-top: 18px;
    font-size: 12px;
    p {
      margin: 0;
      padding: 0; } }
  .logo img {
    display: block;
    margin: 0 auto; }
  .right_txt {
    .phone_numb {
      padding-right: 8px;
      padding-top: 16px;
      p {
        margin: 0;
        padding: 0;
        text-align: right; }
      p:first-child {
        font-family: "Lora Regular";
        font-size: 22px;
        color: #fff;
        line-height: 20px; }
      p:last-child {
        font-family: "Lora Regular";
        font-size: 18px;
        color: #b4b4b4; } }
    .phone_button {
      padding-right: 4px;
      float: right;
      .btn_style {
        color: #fff;
        margin-top: 16px;
        width: auto;
        padding: 9px 21px;
        line-height: 20px; } } } }
#content1,
#content2,
#content3,
#content4,
#content5,
#content6,
#content7 {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover; }
.btn_style {
  display: block;
  color: #000;
  text-align: center;
  border: 2px solid #ea0035;
  font-family: Century Gothic;
  font-size: 12px;
  text-transform: uppercase;
  padding: 10px;
  transition: .5s;
  &:hover {
    background-color: #ea0035;
    text-decoration: none;
    color: #fff; } }

.title {
  text-align: center;
  color: #191a1f;
  font-family: Lora Regular;
  font-size: 35px;
  p {
    margin: 0;
    padding: 0; } }
.subtitle {
  text-align: center;
  color: #191a1f;
  font-family: Lora Regular;
  font-size: 22px; }
span.mark_font {
  color: #ea0036; }
#content1 {
  background-image: url(../images/bg1.jpg);
  position: relative;
  .title {
    margin-top: 180px;
    line-height: 43px;
    margin-bottom: 10px; }
  .first_list {
    min-width: 27%;
    padding-right: 0;
    padding-left: 0; }
  .second_list {
    width: 24%;
    padding-left: 0;
    padding-right: 30px;
    ul.content1_list {
      margin-left: 0;
      padding-left: 18px; } }
  ul.content1_list {
    list-style-image: url(../images/ok.png);
    font-family: Century Gothic;
    font-size: 12px;
    text-transform: uppercase;
    margin: 62px auto;
    margin-left: 45px;
    li {
      margin-top: 8px; }
    li:last-child {
      padding-right: 15px; } }
  .part_block {
    padding: 0 26px; }
  a.btn_style {
    margin-bottom: 210px;
    transition: .5s;
    &:hover {
      background-color: #ea0035;
      text-decoration: none;
      color: #fff; } } }
#content2 {
  background-image: url(../images/bg2.png);
  position: relative;
  z-index: 80;
  margin-top: -85px;
  color: #fff;
  padding-top: 85px;
  margin-bottom: -65px;
  .title {
    margin-top: 85px;
    color: #fff; }
  .subtitle {
    margin-bottom: 85px;
    margin-top: 5px;
    color: #fff; }
  .cnt2_img {
    img {
      margin: 0 auto;
      display: block; } }
  .b_title {
    font-family: Century Gothic;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    width: 80%;
    margin: 0 auto;
    margin-top: 14px; }
  .b_text {
    font-family: Century Gothic;
    font-size: 12px;
    text-align: center;
    margin-top: 12px;
    width: 80%;
    margin: 0 auto;
    line-height: 23px;
    margin-bottom: 210px; } }
#content3 {
  background-image: url(../images/bg3.jpg);
  background-position: bottom center;
  padding-bottom: 75px;
  .title {
    padding-top: 65px;
    margin-bottom: 10px;
    margin-top: 85px;
    line-height: 40px; }
  ul#main_slide_list {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 55px;
    li {
      font-family: Century Gothic;
      text-align: center;
      font-size: 14px;
      color: #454545;
      display: inline-block;
      padding: 5px 10px;
      padding-bottom: 5px;
      border-bottom: 1px dashed #454545;
      margin: 0 10px;
      text-transform: uppercase;
      padding-bottom: 0;
      cursor: pointer; }
    li.active {
      background-color: #f3f1e8;
      color: #000;
      border-bottom: none;
      padding-bottom: 5px; }
    li:hover {
      color: #000; } }
  .owl-carousel .owl-item img {
    width: 62%;
    display: block;
    margin: 0 auto; }
  .owl-item .col-md-4 {
      padding: 0 5%; }
  .owl-item .col-md-4:first-child {
    padding-right: 0;
    padding-left: 10%; }
  .owl-item .col-md-4:last-child {
    padding-right: 10%;
    padding-left: 0; }
  .b3_title {
    text-align: left;
    margin-top: 18px;
    font-family: "Lora Regular";
    font-size: 22px;
    color: #191a1f;
    padding: 0 0 0 20%;
    p {
      margin-bottom: 0; } }
  .artic {
    font-family: Century Gothic;
    font-size: 12px;
    text-align: left;
    color: #191a1f;
    position: relative;
    padding: 0 0 0 20%; }
  .artic::after {
    content: "";
    width: 60%;
    height: 1px;
    background-color: #f3f0e7;
    display: inline-block;
    clear: right;
    position: absolute;
    top: 24px; }
  .descr_sub {
    font-family: Century Gothic;
    text-align: left;
    color: #191a1f;
    position: relative;
    padding: 0 0 0 20%; }
  #block_two .more_colors {
    width: 60%;
    float: left; }
  #block_two p.more_color {
    clear: left; }
  #block_two a.more_info {
    width: 60%; }
  #block_three .descr_sub {
    min-height: 40px; }
  .owl-item #block_four .col-md-4 {
    padding: 0 2.5%; }
  .owl-item #block_four .col-md-4:first-child {
    padding-left: 5%;
    padding-right: 0; }
  .owl-item #block_four .col-md-4:last-child {
    padding-right: 5%;
    padding-left: 0; }
  #block_four {
    .color_block.row, .descr_sub, .artic {
      padding: 0 0 0 15%; }
    .more_colors {
      padding: 0 0 0 15%; }
    .color_descr {
      padding: 0 0 0 15%; }
    .b3_title {
      padding: 0 0 0 15%; }
    a.more_info, p.more_color {
      margin-left: 15%; } }

  .color_block.row {
    margin-top: 18px;
    margin-bottom: 5px;
    padding: 0 0 0 20%; }
  .more_colors {
    padding: 0 0 0 20%; }
  .color_descr {
    font-family: Century Gothic;
    font-size: 14px;
    color: #000;
    text-align: left;
    text-transform: uppercase;
    padding: 0 0 0 20%; }
  p.more_color {
    color: #eb0e41;
    font-family: Century Gothic;
    margin-left: 20%;
    display: inline-block;
    border-bottom: 1px dashed #eb0e41;
    float: left;
    cursor: pointer;
    font-size: 12px;
    transition: .5s; }
  p.more_color:hover {
    color: #000;
    border-color: #000; }
  a.more_info {
    display: block;
    color: #000;
    margin-left: 20%;
    text-align: center;
    border: 2px solid #ea0035;
    font-family: Century Gothic;
    font-size: 12px;
    text-transform: uppercase;
    padding: 10px 15px;
    float: left;
    min-width: 180px;
    clear: left;
    margin-top: 15px;
    transition: .5s;
    &:hover {
      background-color: #ea0035;
      text-decoration: none;
      color: #fff; } } }

#content4 {
  background-image: url(../images/bg4.jpg);
  background-position: bottom center;
  background-size: 100%;
  background-color: #fbfaf6;
  .title {
    margin-top: 75px;
    margin-bottom: 128px;
    line-height: 40px; }
  .cnt4_block2 {
    margin-top: 70px;
    img.blc_img2 {
      margin-left: -65px;
      margin-top: -295px; } }
  .cnt4_block3 {
    img.blc_img3 {
      margin-left: -100px;
      margin-top: -20px; }
    .col-md-8.col-md-offset-1 {
      margin-top: 112px; }
    ul.content4_list {
      li {
        margin-top: 15px; } } }
  .col-md-8.col-md-offset-5 {
    padding-right: 0;
    padding-left: 30px; }
  .cnt4_block4 {
    .col-md-8.col-md-offset-5 {
      margin-top: 75px;
      padding-right: 0px;
      ul.content4_list {
        padding-right: 0;
        li {
          margin-top: 5px; } } }
    img.blc_img4 {
      margin-left: 110px;
      margin-top: 15px; } }
  .col-md-6 {
    position: relative;
    img.blc_img1 {
      width: 120%;
      margin-left: -50px;
      margin-top: -115px; } }
  .b4_title {
    font-family: "Century Gothic";
    font-size: 14px;
    padding-left: 35px;
    line-height: 18px;
    color: #191a1f;
    text-transform: uppercase; }
  ul.content4_list {
    list-style-image: url(../images/ok.png);
    font-family: Century Gothic;
    font-size: 12px;
    margin: 0px auto;
    margin-top: 30px;
    padding: 0;
    padding-left: 35px;
    padding-right: 10px;
    li {
      margin-top: 14px;
      line-height: 14px; } }
  a.btn_style {
    margin-top: -90px;
    max-width: 340px;
    margin-bottom: 125px;
    display: block;
    z-index: 82;
    position: relative; } }
#content5 {
  background-image: url(../images/bg5.png);
  color: #fff;
  position: relative;
  z-index: 80;
  margin-top: -145px;
  padding-top: 185px;
  .col-md-5.col-md-offset-1 {
    padding-left: 0;
    margin-top: 60px; }
  .title {
    color: #fff;
    text-align: left;
    line-height: 40px; }
  ul.content5_list {
    list-style-image: url(../images/ok.png);
    font-family: Century Gothic;
    font-size: 12px;
    margin: 0px auto;
    margin-top: 22px;
    padding: 0;
    text-transform: uppercase;
    margin-bottom: 255px;
    li {
      margin: 12px auto;
      line-height: 16px; } } }
#mapkit-9509 {
  width: 100%;
  height: 720px; }
#map {
  position: relative;
  top: -100px;
  .title {
    text-align: left;
    line-height: 40px;
    margin-top: 145px;
    padding-left: 5px;
    position: absolute;
    margin: 0 25%;
    top: 22.5%; }
  .white_bg {
    width: 100%;
    background-image: url(../images/white_bg.png);
    background-repeat: no-repeat;
    background-position: bottom center;
    position: relative;
    z-index: 80;
    height: 100px;
    margin-top: -100px; } }
#content6 {
  margin-top: -125px;
  .title {
    margin-top: 105px;
    margin-bottom: 105px;
    line-height: 40px; }
  .content6_text {
    color: #37383d;
    padding-left: 10px;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    font-family: "Century Gothic";
    padding-right: 4%;
    margin-bottom: 50px;
    position: relative;
    z-index: 10; }
  .image_cnt6 {
    position: relative;
    img {
      position: absolute;
      top: -90px;
      left: -150px; } }
  .col-md-2.icon {
    padding: 0; }
  .cnt6_blocks {
    margin-top: 40px; }
  .col-md-10, .col-md-6 {
    padding-right: 0; }
  .icon.icon-s-black_ico1 {
    margin-top: -8px;
    margin-left: -5px; }
  .icon.icon-s-black_ico2 {
    margin-left: 20px;
    margin-top: -10px; }
  .block1, .block2, .block3 {
    border-bottom: 1px solid #ebe3e0; }
  .block1, .block3 {
    padding: 0; }
  .block3 {
    min-height: 270px;
    .b6_title {
      padding-left: 20px; }
    .b6_txt {
      padding-left: 20px;
      padding-right: 25px; } }
  .block2 {
    padding: 0;
    margin-top: 30px; }
  .b6_title {
    color: #37383d;
    font-family: "Century Gothic";
    font-size: 14px;
    text-align: left;
    text-transform: uppercase; }
  .b6_txt {
    font-family: "Century Gothic";
    font-size: 12px;
    line-height: 22px;
    margin-top: 12px;
    color: #37383d; }
  .cnt6_blocks {
    margin-bottom: 65px; } }
#content7 {
  background-image: url(../images/bg_l.png);
  position: relative;
  z-index: 80;
  padding-top: 105px;
  color: #fff;
  padding-bottom: 10px;
  .title {
    color: #fff;
    margin-top: 25px; }
  .subtitle {
    color: #fff;
    margin-top: 5px; }
  .form_block {
    margin-top: 98px;
    padding-right: 8px;
    margin-bottom: 120px;
    label {
      font-family: "Century Gothic";
      font-size: 14px;
      text-align: left;
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 2px; }
    input {
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 0;
      height: 40px;
      color: #fff;
      font-family: "Century Gothic";
      width: 262px;
      margin-bottom: 16px; }
    input::-webkit-input-placeholder {
       color: #fff;
       font-family: "Century Gothic"3; }
    input::-moz-placeholder {
      color: #fff;
      font-family: "Century Gothic"; }
    input::-moz-placeholder {
      color: #fff;
      font-family: "Century Gothic"; }
    input::-ms-input-placeholder {
      color: #fff;
      font-family: "Century Gothic"; }
    .modal_btn {
      display: block;
      color: #fff;
      width: 100%;
      min-width: 262px;
      text-align: center;
      background-color: transparent;
      border: 2px solid #ea0035;
      font-family: Century Gothic;
      font-size: 12px;
      margin-top: 18px;
      margin-bottom: 12px;
      text-transform: uppercase;
      padding: 10px;
      transition: .5s;
      &:hover {
        background-color: #ea0035;
        text-decoration: none;
        color: #fff; } } }
  .subtext, .check_group {
    font-family: "Century Gothic";
    text-align: left; }
  .subtext {
    font-size: 12px; }
  .check_group {
    font-size: 12px;
    cursor: pointer;
    margin-top: 5px;
    -moz-user-select: none;
    p {
      letter-spacing: 0px;
      width: 115%;
      a {
        color: #fff;
        &:focus {
          color: #fff; } } } } }
#check_block,#check_block1,#check_block2,#check_block3,#check_block4 {
  display: block;
  float: left;
  margin-right: 10px;
  width: 22px;
  height: 22px;
  border: 1px solid #fff;
  margin-bottom: 40px;
  transition: .5s;
  background-image: none;
  background-size: 11px 11px;
  background-repeat: no-repeat;
  background-position: center; }
footer {
  background-color: #191a1f;
  padding-top: 18px;
  padding-bottom: 20px;
  color: #fff;
  .container {
    padding: 0px; }
  .logo {
    img {
      width: 100%; } }
  .f_text {
    font-family: Century Gothic;
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    padding: 0;
    line-height: 20px;
    padding-top: 6px; }
  .orange_logo {
    color: #fff;
    font-family: Century Gothic;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    display: block;
    padding-top: 15px;
    margin-top: -12px;
    padding-right: 45px;
    a {
      color: #fff;
      text-decoration: none !important;
      &:hover {
        color: #fff; }
      p {
        margin: 0; } }
    img {
      display: block;
      margin: 0 auto; } }
  .f_back {
    padding-right: 22px;
    padding-left: 5px;
    margin-top: -20px;
    .phone_numb {
      a {
        color: #fff;
        font-size: 22px;
        text-align: right;
        cursor: default;
        font-family: "Lora Regular";
        display: block;
        width: 100%;
        &:hover {
          color: #fff;
          text-decoration: none; } } }
    .phone_button {
      a {
        color: #fff;
        text-align: center;
        &:hover {
          color: #fff; } } } } }
/* modal style */
.modal-dialog {
  width: 100%;
  max-width: 985px; }
.close {
  opacity: 1; }

.modal-content {
  border: none;
  border-radius: 0%;
  box-shadow: none;
  background-color: transparent; }
.modal-header {
  border-bottom: none;
  padding-bottom: 0; }
.modal-body {
  padding-top: 0;
  .title {
    color: #fff;
    font-family: "Lora Regular";
    font-size: 30px;
    text-align: left;
    line-height: 32px;
    margin-bottom: 10px; }
  .subtitle {
    font-family: "Lora Regular";
    font-size: 18px;
    color: #fff;
    text-align: left;
    margin-bottom: 10px;
    line-height: 24px; }
  form {
    width: 78%;
    max-width: 262px;
    label {
      font-family: "Century Gothic";
      font-size: 14px;
      text-align: left;
      color: #fff;
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 2px; }
    .input-group {
      margin-bottom: 10px; }
    input {
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 0;
      height: 40px;
      color: #fff;
      font-family: "Century Gothic";
      width: 262px;
      margin-bottom: 16px; }
    input::-webkit-input-placeholder {
      color: #fff;
      font-family: "Century Gothic"3; }
    input::-moz-placeholder {
      color: #fff;
      font-family: "Century Gothic"; }
    input::-moz-placeholder {
      color: #fff;
      font-family: "Century Gothic"; }
    input::-ms-input-placeholder {
      color: #fff;
      font-family: "Century Gothic"; }
    .modal_btn {
      display: block;
      color: #fff;
      width: 100%;
      min-width: 262px;
      text-align: center;
      background-color: transparent;
      border: 2px solid #ea0035;
      font-family: Century Gothic;
      font-size: 12px;
      margin-top: 0px;
      margin-bottom: 0px;
      text-transform: uppercase;
      padding: 10px;
      transition: .5s;
      &:hover {
        background-color: #ea0035;
        text-decoration: none;
        color: #fff; } } }
  .butt {
    margin-bottom: 5px !important; }
  .subtext, .check_group {
    margin-bottom: 0 !important;
    font-family: "Century Gothic";
    text-align: left; }
  .subtext {
    font-size: 12px;
    color: #fff; }
  .check_group {
    font-size: 12px;
    cursor: pointer;
    margin-top: 5px;
    -moz-user-select: none;
    p {
      letter-spacing: 0px;
      width: 115%;
      color: #fff; } } }
#thanks_modal {
  .modal-content {
    background-image: url(../images/thx_bg.png);
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    min-height: 340px; }
  span.title_sub {
    font-size: 18px; }
  .subtitle {
    font-family: Century Gothic;
    font-size: 12px; }
  .modal-body {
    padding-top: 25px;
    width: 55%;
    margin: 0 auto; }
  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
    width: 80%;
    margin: 0 auto; } }
#back_call_modal {
  .modal-content {
    background-image: url(../images/modal_bg4.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;
    padding: 0px 70px 150px 55%; } }
#prob_modal {
  .modal-content {
    background-image: url(../images/modal_bg1.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;
    padding: 0px 70px 150px 55%; } }
#pomad_modal {
  .modal-content {
    background-image: url(../images/modal_bg2.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    min-height: 762px;
    padding: 10px 6% 20px 6%; }
  .sbtitle {
    color: #191a1f;
    font-family: "Lora Regular";
    font-size: 18px;
    text-align: left; }
  .title {
    color: #191a1f;
    font-size: 30px;
    line-height: 40px; }
  .subtitle {
    color: #191a1f; }
  .descr_pomad {
    padding: 0 8%; }
  .description {
    p {
      font-family: Century Gothic;
      font-size: 12px;
      color: #191a1f; } } }
#img_pomad {
  display: block;
  margin: 0 auto;
  text-align: center; }
#buypomad_modal {
  .modal-content {
    background-image: url(../images/modal_bg3.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;
    padding: 0px 70px 150px 55%; } }

/* adaptive fix */
@media (min-width: 1500px) and (max-width: 1900px) {
  header {
    .phone_numb {
      width: 45%; }
    .phone_button {
      width: 55%; } }
  .parall_container .layer10 {
    right: 550px; }
  .parall_container .layer3 {
    right: 520px; }
  .parall_container .layer5 {
    right: 305px; }
  #map {
    top: -150px; }
  #content6 {
    margin-top: -205px; }
  footer {
    .orange_logo {
      margin-left: 12%; }
    .f_back {
      margin-top: -5px;
      float: right; } } }
@media (width: 1440px) {
  #map {
    top: -180px; }
  header {
    .logo {
      width: 28%; }
    .right_txt {
      width: 36%; }
    .phone_numb {
      width: 40%; }
    .phone_button {
      width: 60%; } }
  #content6 {
    margin-top: -235px; } }
@media (min-width: 1300px) and (max-width: 1400px) {
  header {
    .logo {
      width: 25%; }
    .right_txt {
      width: 40%; }
    .phone_numb {
      width: 40%; }
    .phone_button {
      width: 60%; } }
  #content1 {
    .container {
      position: relative;
      z-index: 10; } }
  .parall_container .layer7 {
    top: -160px;
    left: -125px; }
  .parall_container .layer11 {
    bottom: 235px; }
  .parall_container .layer2 {
    left: -165px;
    top: -80px; }
  .parall_container .layer1 {
    right: 335px; }
  .parall_container .layer3 {
    bottom: 235px; }
  .parall_container .layer5 {
    right: 320px; }
  .parall_container .layer10 {
    right: 645px;
    bottom: 160px; }
  #content4 .cnt4_block4 img.blc_img4 {
    margin-left: 70px; }
  #map {
    top: -200px; }
  #content6 {
    margin-top: -245px; }
  #content6 .image_cnt6 img {
    left: -200px;
    footer {}
    .f_back {
      margin-top: -5px;
      float: right; } } }
@media (min-width: 1200px) and (max-width: 1300px) {
  header {
    .left_txt {
      padding-right: 0; }
    .logo {
      width: 25%; }
    .right_txt {
      width: 40%;
      padding-left: 0;
      padding-right: 0; } }
  img.parallax-layer {
    max-width: 260px; }
  .parall_container .layer11 {
    bottom: 195px; }
  .parall_container .layer10 {
    bottom: 145px; }
  .parall_container .layer3 {
    bottom: 130px; }
  .parall_container .layer5 {
    right: 305px; }
  #content4 .cnt4_block2 img.blc_img2 {
    margin-left: -65px;
    margin-top: -230px;
    width: 100%; }
  #content4 .cnt4_block4 img.blc_img4 {
    margin-left: 110px;
    margin-top: 90px;
    width: 80%; }
  #map {
    top: -240px; }
  #content6 {
    margin-top: -295px; }
  #content6 .image_cnt6 img {
    position: absolute;
    top: 60px;
    left: -150px;
    width: 140%; } }
@media (min-width: 1000px) and (max-width: 1200px) {
  header {
    .left_txt {
      padding-right: 0;
      p {
        font-size: 10px; } }
    .logo {
      width: 20%;
      img {
        width: 80%;
        padding-top: 10px; } }
    .right_txt {
      width: 45%;
      padding-left: 0;
      padding-right: 0;
      .phone_numb {
        padding-left: 0;
        width: 40%;
        p:first-child {
          font-size: 20px; }
        p:last-child {
          font-size: 18px; } }
      .phone_button {
        width: 55%; } } }
  img.parallax-layer {
    max-width: 260px; }
  .parall_container .layer11 {
    bottom: 195px; }
  .parall_container .layer10 {
    bottom: 145px;
    right: 330px; }
  .parall_container .layer3 {
    bottom: 130px;
    right: 300px; }
  .parall_container .layer5 {
    right: 200px; }
  #content1 ul.content1_list {
    margin-left: 0; }
  #content1 .second_list {
    width: 33%;
    padding-left: 25px;
    padding-right: 0; }
  #content1 .part_block {
    padding: 0 26px;
    width: 40%;
    margin: 0 30%; }
  #content3 .owl-item #block_four .col-md-4 {
    padding: 0 0.5%; }

  #content3 .owl-item #block_four .col-md-4:first-child {
    padding-left: 2%;
    padding-right: 0; }
  .clean {
    height: 15px; }
  #content3 .descr_sub,#content3 .b3_title,#content3 .artic, #content3 .color_block.row, #content3 .color_descr, {
    padding-left: 5%; }
  #content3 a.more_info, #content3 p.more_color {
    margin-left: 5%;
    font-size: 11px; }
  #content3 .owl-item #block_four .col-md-4:last-child {
    padding-right: 2%;
    padding-left: 0; }
  #content3 .color_descr {
    font-size: 12px; }
  #content3 .descr.col-md-12 {
    width: 80%;
    margin: 0 20%; /* mb fix ---------------------------------------------- */; }
  #content4 .row.cnt4_block1 {
    margin-top: -50px; }
  #content4 .col-md-6 img.blc_img1 {
    margin-top: -35px; }
  #content4 .cnt4_block2 img.blc_img2 {
    margin-top: -185px;
    width: 120%; }
  #content4 .cnt4_block3 img.blc_img3 {
    margin-top: 45px;
    width: 145%; }
  #content4 .cnt4_block4 img.blc_img4 {
    margin-left: 100px;
    margin-top: 205px;
    width: 85%;
    margin-bottom: -30px; }
  #content4 .cnt4_block4 .col-md-8.col-md-offset-5 {
    margin-top: 20px; }
  #content4 .cnt4_block4 {
    padding-left: 0; }
  #content4 a.btn_style {
    margin-top: -150px;
    width: 115%;
    margin-left: -90px;
    margin-bottom: 225px; }
  #content4 ul.content4_list {
    padding-right: 0; }
  #content5 .col-md-5.col-md-offset-1 {
    margin-left: 5%;
    margin-top: -70px; }
  #map {
    top: -260px; }
  #content6 {
    margin-top: -315px; }
  #content6 .image_cnt6 img {
    position: absolute;
    top: 255px;
    left: -150px;
    width: 160%; }
  #content6 .content6_text {
    padding-left: 0; }
  #content6 .col-md-10, #content6 .col-md-6 {
    padding-right: 0;
    padding-left: 0; }
  #content6 .icon.icon-s-black_ico2 {
    margin-left: 15px; }
  #content6 .block3 .b6_txt {
    padding-right: 0; }
  #content6 .cnt6_blocks {
    padding-left: 0; }
  #content7 .form_block {
    width: 30%; }
  footer {
    .f_text {
      width: 30%; }
    .orange_logo {
      width: 25%;
      margin-left: 0;
      margin-top: 0px;
      padding-top: 10px; }
    .f_back {
      width: 25%;
      margin-top: 5px; } } }
@media (min-width: 740px) and (max-width: 770px) {
  header {
    .left_txt {
      display: none; }
    .logo {
      float: left;
      width: 25%;
      img {
        width: 100%;
        padding-top: 6px; } }
    .right_txt {
      width: 70%;
      float: right;
      .phone_numb {
        width: 40%;
        float: left;
        padding-left: 0; } } }
  img.parallax-layer {
    max-width: 200px; }
  .parall_container .layer11 {
    bottom: 195px; }
  .parall_container .layer10 {
    bottom: 145px;
    right: 330px; }
  .parall_container .layer3 {
    bottom: 130px;
    right: 300px; }
  .parall_container .layer5 {
    right: 200px;
    bottom: 185px; }
  .parall_container .layer8 {
    left: -70px;
    bottom: 230px; }
  .parall_container .layer9 {
    right: 120px; }
  .title {
    font-size: 28px; }
  #content1 ul.content1_list {
    margin: 25px auto; }
  #content1 ul.content1_list li:last-child {
    padding-right: 0; }
  #content1 .second_list, #content1 .first_list {
    width: 50%;
    padding-right: 0;
    padding-left: 30px;
    float: left; }
  #content1 .second_list {
    padding-right: 30px; }
  #content1 .part_block {
    width: 55%;
    margin: 0 auto; }
  #content2 {
    margin-top: -225px;
    padding-top: 55px;
    padding-bottom: 30px;
    .col-md-3 {
      width: 50%;
      float: left; } }
  #content2 .b_text {
    margin-bottom: 30px; }
  #content3 .owl-item .col-md-4 {
    padding-right: 0 !important;
    padding-left: 30px !important;
    width: 33.3333% !important;
    float: left; }
  #content3 .descr_sub,#content3 .b3_title,#content3 .artic, #content3 .color_block.row, #content3 .color_descr, {
      padding-left: 5%; }
  #content3 a.more_info, #content3 p.more_color {
    margin-left: 5%;
    font-size: 11px; }
  #content3 #block_two .more_colors {
    width: 100%;
    float: left;
    max-width: 195px; }
  #content3 .more_colors {
    padding: 0 0 0 5%; }
  #content3 #block_four .more_colors {
    padding: 0 0 0 0%;
    width: 100%;
    max-width: 190px; }
  #content3 .owl-item #block_four .col-md-4:last-child {
    padding-right: 2%;
    padding-left: 0; }
  #block_two .col-md-6 {
    width: 50%;
    float: left;
    padding-left: 10%; }
  #block_three .col-md-3 {
    width: 25%;
    float: left;
    margin: 0 0% 30px 0%;
    padding: 0 10px; }
  #content3 #block_four a.more_info {
    margin-left: 0;
    width: 100% !important; }
  #content3 .owl-item #block_four .col-md-4 {
    padding-right: 25px !important;
    padding-left: 25px !important; }
  #content3 #block_four .b3_title, #content3 #block_four a.more_info, #content3 #block_four p.more_color {
    padding-left: 0; }
  #content3 #block_four .color_block.row, #content3 #block_four .descr_sub, #content3 #block_four .artic, #content3 #block_four .color_descr {
    padding-left: 0; }
  #content3 #block_four p.more_color {
    margin-left: 0; }
  .color_descr p {
    font-size: 10px; }
  #block_three a.more_info {
    margin-left: 10px;
    font-size: 10px;
    width: 90%;
    min-width: 115px;
    font-size: 13px; }
  #content3 #block_two a.more_info {
    width: 100%; }
  #block_three .artic {
    min-height: 35px; }
  #content3 #block_four a.more_info {
    font-size: 14px;
    text-align: center;
    padding: 5px 0; }
  #content4 .col-md-6 {
    position: relative;
    width: 50%;
    float: left; }
  #content4 .col-md-6 img.blc_img1 {
    margin-top: 0; }
  .row.cnt4_block1 {
    margin-top: -90px; }
  #content4 .cnt4_block2 img.blc_img2 {
    margin-left: -65px;
    margin-top: -110px;
    width: 120%; }
  #content4 .cnt4_block3 img.blc_img3 {
    margin-left: -20px;
    margin-top: 75px;
    width: 125%; }
  #content4 .cnt4_block4 img.blc_img4 {
    margin-left: 0;
    margin-top: 205px;
    width: 100%;
    margin-bottom: -35px; }
  #content4 a.btn_style {
    margin-top: -60px;
    margin-left: 25px;
    top: -75px; }
  #content5 .col-md-5.col-md-offset-1 {
    padding-left: 20px;
    margin-top: 0; }
  #content6 {
    margin-top: -175px;
    margin-bottom: 100px;
    .title {
      margin-bottom: 30px; } }
  #content6 .image_cnt6 img {
    position: absolute;
    right: 0 !important;
    left: auto;
    width: 32%;
    top: 285px; }
  .cnt6_blocks .col-md-6 {
    width: 50%;
    float: left; }
  #content6 .icon {
    margin: 15px 18px !important; }
  #content6 i.icon.icon-s-black_ico1, #content6 i.icon.icon-s-black_ico3 {
    margin-left: 0 !important; }
  #content6 .cnt6_blocks {
    margin-top: -25px; }
  #content6 .content6_text {
    padding-left: 50px; }
  #content7 .form_block {
    float: right;
    width: 50%; }
  #content7 .check_group p {
    letter-spacing: 0px;
    width: 90%; }
  #content4 .cnt4_block4 .col-md-8.col-md-offset-5 {
    margin-top: 25px; }
  footer {
    .logo {
      width: 25%;
      float: left; }
    .f_text {
      width: 40%;
      float: left; }
    .orange_logo {
      float: left;
      clear: left;
      width: 100%;
      text-align: left;
      img {
        float: left; } }
    .f_back {
      float: right;
      margin-top: -115px; } }
  /* modal fix  */
  #back_call_modal .modal-content {
    background-size: cover;
    padding: 0px 15px 140px 55%; }
  #prob_modal .modal-content {
    background-size: cover;
    padding: 0px 10px 150px 55%;
    .modal-body form .modal_btn {
      min-width: 230px; } }
  .col-md-6.image_pomad {
    width: 50%;
    float: left;
    height: 100%; }
  .col-md-6.descr_pomad {
    float: left;
    width: 50%;
    padding: 0 0px 0 30px !important; }
  img#img_pomad {
    width: 120%; }
  #buypomad_modal .modal-content {
    background-size: cover;
    padding: 0px 10px 150px 52%; } }

@media (min-width: 300px) and (max-width: 420px) {
  #thanks_modal .modal-body {
    width: 85%; }
  #thanks_modal .modal-header {
    width: 100%; }
  #thanks_modal span.title_sub {
    font-size: 15px; }
  #thanks_modal .subtitle {
    font-family: Century Gothic;
    font-size: 10px; }
  header {
    padding: 0;
    .left_txt {
      display: none; }
    .logo {
      width: 100%;
      padding: 0;
      width: 90%; }
    .right_txt {
      float: none;
      width: 100%;
      background-color: #191a1f;
      padding: 0;
      .phone_numb {
        width: 100%;
        float: none;
        padding-top: 5px;
        p {
          text-align: center; } }
      .phone_button {
        width: 100%;
        float: none;
        padding-bottom: 10px;
        a.btn_style {
          width: 80%;
          margin: 0 auto; } } } }
  #content1 {
    .parall_container {
      display: none; } }
  .title {
    font-size: 25px; }
  .subtitle {
    font-size: 20px; }
  #content1 .title {
    line-height: 32px;
    margin-top: 130px;
    margin-bottom: 30px; }
  #content1 ul.content1_list {
    width: 100%;
    margin: 0 auto;
    padding: 0 60px; }
  #content1 .second_list {
    width: 100%;
    padding: 0 40px; }
  #content1 .part_block {
    padding: 0 26px;
    margin-top: 20px; }
  #content1 a.btn_style {
    margin-bottom: 85px; }
  #content2 {
    margin-top: -36px;
    padding-top: 0;
    background-position: 0;
    background-color: #a15162;
    background-image: none; }
  #content2 .title {
    margin-top: 30px;
    color: #fff; }
  #content2 .subtitle {
    margin-bottom: 40px; }
  #content2 .b_text {
    margin-bottom: 50px; }
  #content3 .title {
    padding-top: 20px;
    margin-bottom: 20px;
    margin-top: 85px;
    font-size: 20px;
    line-height: 25px; }
  #content3 ul#main_slide_list li:last-child {
    margin: 0 auto;
    text-align: center;
    margin-top: 10px !important;
    margin-left: 25px !important; }
  #block_two .col-md-6 {
    margin-bottom: 30px;
    float: left;
    width: 100%;
    padding: 0 6%;
    .img.col-md-12 {
      padding: 0;
      margin-bottom: 15px; } }
  #block_two .descr.col-md-12 {
    padding: 0; }
  #content3 .owl-carousel .owl-item #block_one img {
    width: 25%;
    display: block;
    margin: 0 auto; }
  #content3 .artic::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #f3f0e7;
    display: inline-block;
    position: absolute;
    top: 24px;
    margin: 0 auto !important;
    display: block;
    text-align: center;
    padding: 0 !important;
    left: 0; }
  #content3 .more_colors {
    padding: 0 !important;
    width: 100%;
    max-width: 180px;
    margin: 0 auto; }
  #content3 #block_two p.more_color {
    clear: left;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px; }
  #content3 #block_two .more_colors {
    width: 100%;
    float: none;
    padding-left: 0 !important;
    margin: 0 auto;
    max-width: 180px; }
  div#block_one {
    margin: 0 auto; }
  #content3 .owl-item .col-md-4 {
    padding: 0 10px !important;
    float: left;
    width: 100%;
    margin-bottom: 20px; }
  #content3 .b3_title, #content3 .artic, #content3 .color_block.row {
    padding: 0 10px;
    text-align: center !important;
    margin: 0 auto !important;
    width: 100% !important; }
  #content3 #block_four .descr_sub, #content3 #block_four .artic {
    padding: 0 !important;
    text-align: center !important; }
  #content3 p.more_color {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    margin-bottom: 10px; }
  #content3 .color_block.row {
    width: 100% !important;
    max-width: 180px;
    padding: 0 !important;
    margin: 0 auto !important; }
  #content3 #block_two a.more_info {
    width: 75%;
    margin-left: 12%; }
  #content3 .owl-carousel .owl-item #block_three img {
    width: 25%;
    margin: 0 auto; }
  #block_three .col-md-3 {
    float: left;
    width: 100%;
    margin-bottom: 20px; }
  #content3 #block_three a.more_info {
    min-width: 200px; }
  #content3 .owl-carousel .owl-item #block_four img {
    width: 25%;
    margin: 0 auto;
    text-align: center;
    display: block; }
  #content3 #block_four .color_block.row, #content3 #block_four .artic, #content3 #block_four .b3_title {
    padding: 0;
    text-align: left; }
  #content3 #block_four .descr_sub {
    text-align: left;
    padding-left: 20%; }
  #content3 #block_four .color_descr {
    padding: 0 !important;
    text-align: center !important;
    margin-top: 10px; }
  #content3 #block_four p.more_color {
    margin: 0 auto;
    width: 90%;
    padding: 0;
    text-align: center;
    margin: 0px 5% !important;
    margin-bottom: 10px !important; }
  #content3 .descr_sub {
    font-family: Century Gothic;
    text-align: center;
    color: #191a1f;
    position: relative;
    padding: 0;
    min-height: 20px !important; }
  #content3 #block_two .color_block.row {
    width: 100%;
    max-width: 180px;
    margin: 15px auto; }
  #content3 .color_descr {
    padding: 0;
    text-align: center; }
  #content4 .title {
    font-size: 23px;
    line-height: 32px; }
  #content4 .cnt4_block2 {
    margin-top: 390px; }
  #content4 .cnt4_block2 img.blc_img2 {
    margin-left: -65px;
    margin-top: -795px;
    width: 115%; }
  #content4 .cnt4_block3 img.blc_img3 {
    margin-left: 0;
    margin-top: 15px;
    width: 100%; }
  #content4 .cnt4_block3 .col-md-8.col-md-offset-1 {
    margin-top: 0; }
  #content4 .row.cnt4_block4 {
    margin-top: 360px; }
  #content4 .cnt4_block4 img.blc_img4 {
    margin-left: 0;
    margin-top: -990px;
    width: 100%; }
  #content6 .icon.icon-s-black_ico1 {
    margin-top: -8px;
    margin-left: 0; }
  #content4 a.btn_style {
    margin-top: 0;
    max-width: 90%;
    margin-bottom: 125px;
    display: block;
    z-index: 82;
    position: relative;
    margin: 0 auto;
    margin-bottom: 125px; }
  #content4 .col-md-4.col-md-offset-4 {
    padding-right: 0; }
  section#content5 {
    background-position: 35% 0;
    padding-left: 30px; }
  #map {
    top: -135px;
    .title {
      margin: 0 12%; } }
  #content6 .title {
    margin-top: 40px;
    margin-bottom: 25px;
    line-height: 32px;
    font-size: 21px; }
  #content6 .image_cnt6 img {
    display: none; }
  #content6 .cnt6_blocks .col-md-6 {
    padding: 0;
    width: 100%;
    margin: 40px auto; }
  #content6 .cnt6_blocks {
    margin-top: 0; }
  #content6 .content6_text {
    margin-bottom: 10px; }
  #content6 .col-md-10 {
    padding-right: 0;
    padding-left: 0; }
  #content6 .block3 .b6_title,
  #content6 .block3 .b6_txt {
    padding-left: 0; }
  #content6 .icon.icon-s-black_ico2 {
    margin-left: 0;
    margin-top: -10px;
    margin-bottom: 5px; }
  #content6 {
    margin-bottom: -80px; }
  #content7 {
    background-position: 0 0;
    padding-top: 25px;
    margin-top: 25px;
    margin-bottom: -15px; }
  #content7 .title {
    font-size: 30px;
    line-height: 35px; }
  #content7 .check_group p {
    letter-spacing: 0px;
    width: 95%; }
  #content7 .form_block {
    margin-top: 50px;
    padding-right: 0;
    margin-bottom: 120px; }
  #content7 .form_block input, #content7 .form_block .modal_btn {
    width: 305px; }
  #content4 .col-md-6 img.blc_img1 {
    width: 110%;
    margin-left: -40px;
    margin-top: -115px; }
  footer .logo img {
    width: 40%;
    margin: 0 auto;
    text-align: center;
    display: block; }
  footer .f_text {
    padding-left: 20px;
    text-align: center;
    padding-bottom: 10px; }
  footer .orange_logo {
    position: absolute;
    padding-top: 85px;
    background-color: #191a1f;
    width: 100%;
    padding-bottom: 10px;
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0; }
  footer .f_back {
    padding-right: 20px;
    padding-left: 20px;
    margin-top: -10px;
    float: left;
    width: 100%;
    text-align: left;
    .phone_numb {
      a:first-child {
        text-align: center; } } }
  footer .f_back .phone_button a {
    color: #fff;
    text-align: center;
    width: 80%;
    margin: 0 auto; }
  #back_call_modal .modal-content, #prob_modal .modal-content, #buypomad_modal .modal-content {
    background-color: #c66376;
    padding: 0;
    margin: 0 auto;
    width: 100%;
    background-image: none;
    .modal-body {
      position: relative;
      padding: 20px 0 20px 50px; } }
  .modal-dialog {
    margin: 0; }
  #pomad_modal .modal-content {
    background-image: none;
    min-height: auto;
    padding: 10px;
    background-color: #f7f6ef; }
  #img_pomad {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 35%;
    margin-bottom: 25px; }
  .modal-body .title {
    font-size: 24px; } }
@media (width: 320px) {
  #content7 .form_block input, #content7 .form_block .modal_btn {
    width: 265px; }
  #content3 .title {
    font-size: 18px; }
  #content3 a.more_info {
    margin: 0;
    width: 100%; }
  #content3 .color_block.row {
    margin: 20px 10% 10px 15%;
    width: 75%; }
  #content3 #block_two a.more_info {
    width: 100%;
    margin-left: 0; }
  #content3 #block_four a.more_info {
    width: 89%;
    margin: 0 5%;
    display: block; }
  #content3 #block_four .color_descr {
    padding: 0 0 0 15%;
    p {
      font-size: 12px; } }
  #content4 .title {
    font-size: 18px;
    line-height: 25px; }
  #content4 .cnt4_block4 img.blc_img4 {
    margin-top: -1065px; }
  section#content5 {
    background-position: 35% 0;
    padding-left: 25px;
    padding-top: 60px; }
  #content5 ul.content5_list {
    font-size: 10px; }
  #map .title {
    margin: 0 10%;
    width: 80%;
    font-size: 20px;
    line-height: 28px; }
  footer .f_text {
    padding-right: 20px; }
  #back_call_modal .modal-content .modal-body, #prob_modal .modal-content .modal-body, #buypomad_modal .modal-content .modal-body {
    position: relative;
    padding: 20px 0 20px 30px; } }

@media (min-width: 550px) and (max-width: 740px) {
  header {
    padding: 0;
    .left_txt {
      display: none; }
    .logo {
      width: 100%;
      padding: 0;
      width: 90%; }
    .right_txt {
      float: none;
      width: 100%;
      background-color: #191a1f;
      padding: 0;
      .phone_numb {
        width: 100%;
        float: none;
        padding-top: 5px;
        p {
          text-align: center; } }
      .phone_button {
        width: 100%;
        float: none;
        padding-bottom: 10px;
        a.btn_style {
          width: 45%;
          margin: 0 auto; } } } }
  #content1 {
    .parall_container {
      display: none; } }
  .title {
    font-size: 25px; }
  .subtitle {
    font-size: 20px; }
  #content1 .title {
    line-height: 32px;
    margin-top: 130px;
    margin-bottom: 30px; }
  #content1 ul.content1_list {
    width: 100%;
    margin: 0 auto;
    padding: 0 60px; }
  #content1 .second_list {
    width: 100%;
    padding: 0 40px; }
  #content1 .part_block {
    padding: 0 26px;
    margin-top: 20px; }
  #content1 a.btn_style {
    margin-bottom: 85px;
    width: 65%; }
  #content2 {
    margin-top: -36px;
    padding-top: 0;
    background-position: 0;
    background-color: #a15162;
    background-image: none; }
  #content2 .title {
    margin-top: 30px;
    color: #fff; }
  #content2 .subtitle {
    margin-bottom: 40px; }
  #content2 .b_text {
    margin-bottom: 50px; }
  #content3 .title {
    padding-top: 20px;
    margin-bottom: 20px;
    margin-top: 85px;
    font-size: 20px;
    line-height: 25px; }
  #content3 ul#main_slide_list li:last-child {
    margin: 0 auto;
    text-align: center;
    margin-top: 10px !important;
    margin-left: 25px !important; }
  #block_two .col-md-6 {
    margin-bottom: 0;
    width: 60%;
    margin: 0 auto;
    position: relative;
    float: none;
    min-height: 565px; }
  #content3 #block_two p.more_color {
    clear: left;
    margin-left: 0; }
  #block_two .img.col-md-12 {
    padding: 0;
    img {
      width: 100%; } }
  #content3 .owl-carousel .owl-item #block_one img {
    width: 25%;
    display: block;
    margin: 0 20%; }
  #content3 .artic::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #f3f0e7;
    display: inline-block;
    position: absolute;
    top: 24px;
    margin: 0 auto !important;
    display: block;
    text-align: center;
    padding: 0 !important;
    left: 0; }
  div#block_one {
    margin: 0 auto; }
  #content3 .owl-item .col-md-4 {
    padding: 0 !important;
    float: left;
    width: 60%;
    margin-bottom: 20px;
    margin: 0 20%;
    min-height: 0; }
  #content3 #block_four .more_colors {
    padding: 0;
    margin-bottom: 10px;
    width: 100%;
    max-width: 180px;
    margin: 0; }
  #content3 #block_one .color_descr, #content3 #block_one .more_colors {
    padding: 0; }
  #content3 #block_one .more_colors.row {
    width: 100%;
    margin: 0;
    max-width: 180px; }
  #content3 p.more_color, #content3 a.more_info {
    margin-left: 0; }
  #content3 .b3_title, #content3 .artic, #content3 .color_block.row {
    padding: 0;
    text-align: left;
    margin: 0 auto;
    width: 100%; }
  #content3 #block_three .b3_title,
  #content3 #block_three .artic,
  #content3 #block_three .color_block.row {
    padding: 0;
    text-align: left;
    margin: 0 auto;
    width: 100%; }
  #content3 .color_block.row {
    margin: 10px auto; }
  #content3 #block_two a.more_info {
    width: 75%;
    margin-left: 0%; }
  #content3 .owl-carousel .owl-item #block_three img {
    width: 25%;
    margin: 0 15%; }
  #block_three .col-md-3 {
    float: left;
    width: 50%;
    margin-bottom: 20px; }
  #content3 #block_three a.more_info {
    min-width: 200px;
    width: 100%;
    margin-left: 0; }
  #content3 .owl-carousel .owl-item #block_four img {
    width: 30%;
    margin: 0 auto;
    text-align: center;
    display: block;
    margin: 0; }
  #content3 #block_four .color_block.row {
    width: 100%;
    max-width: 180px;
    margin: 0; }
  #content3 ul#main_slide_list {
    margin-bottom: 10px; }
  #content3 #block_four .color_block.row, #content3 #block_four .artic, #content3 #block_four .b3_title {
    padding: 0;
    text-align: left; }
  #content3 #block_four .descr_sub {
    text-align: left;
    padding-left: 0%; }
  #content3 #block_four a.more_info {
    margin-left: 0%; }
  #content3 #block_four .color_descr {
    padding: 10px 0; }
  #content3 #block_four p.more_color {
    margin-left: 0%;
    padding: 0; }
  #content3 .descr_sub {
    font-family: Century Gothic;
    color: #191a1f;
    position: relative;
    padding: 0;
    font-size: 12px;
    min-height: 20px !important;
    width: 100%;
    text-align: left;
    margin: 0 auto; }
  #content3 #block_two .color_block.row {
    width: 100%;
    margin: 20px auto;
    max-width: 180px;
    margin-left: 0; }
  #content3 #block_two .color_descr {
    padding: 0; }
  #content3 #block_two .more_colors {
    width: 100%;
    float: left;
    margin: 0;
    padding: 0;
    max-width: 180px; }
  #content4 .title {
    font-size: 25px;
    line-height: 32px; }
  #content4 .cnt4_block2 {
    margin-top: 390px; }
  #content4 .cnt4_block2 img.blc_img2 {
    margin-left: 40px;
    margin-top: -640px;
    width: 75%; }
  #content4 .cnt4_block3 img.blc_img3 {
    margin-left: 0;
    margin-top: 15px;
    width: 100%; }
  #content4 .cnt4_block3 .col-md-8.col-md-offset-1 {
    margin-top: 0; }
  #content4 .row.cnt4_block4 {
    margin-top: 300px; }
  #content4 .cnt4_block4 img.blc_img4 {
    margin-left: 0;
    margin-top: -830px;
    width: 55%; }
  #content4 a.btn_style {
    margin-top: 0;
    max-width: 90%;
    margin-bottom: 125px;
    display: block;
    z-index: 82;
    position: relative;
    margin: 0 auto;
    margin-bottom: 125px; }
  #content4 .col-md-4.col-md-offset-4 {
    padding-right: 0; }
  section#content5 {
    background-position: 35% 0;
    padding-left: 30px; }
  #map {
    top: -135px;
    .title {
      margin: 0 12%; } }
  #content6 .title {
    margin-top: 40px;
    margin-bottom: 25px;
    line-height: 32px;
    font-size: 21px; }
  #content6 .image_cnt6 img {
    display: none; }
  #content6 .cnt6_blocks .col-md-6 {
    padding: 0;
    width: 100%;
    margin: 40px auto; }
  #content6 .cnt6_blocks {
    margin-top: 0; }
  #content6 .content6_text {
    margin-bottom: 10px; }
  #content6 .col-md-10 {
    padding-right: 0;
    padding-left: 0; }
  #content6 .block3 .b6_title,
  #content6 .block3 .b6_txt {
    padding-left: 0; }
  #content6 .icon.icon-s-black_ico2 {
    margin-left: 0;
    margin-top: -10px;
    margin-bottom: 5px; }
  #content6 {
    margin-bottom: -80px; }
  #content7 {
    background-position: 0 0;
    padding-top: 25px;
    margin-top: 25px;
    margin-bottom: -15px; }
  #content7 .title {
    font-size: 30px;
    line-height: 35px; }
  #content7 .check_group p {
    letter-spacing: 0px;
    width: 95%; }
  #content7 .form_block {
    margin-top: 50px;
    padding-right: 0;
    margin-bottom: 120px;
    width: 80%; }
  #content7 .form_block input, #content7 .form_block .modal_btn {
    width: 100%;
    max-width: 300px; }
  #content4 .col-md-6 img.blc_img1 {
    width: 110%;
    margin-left: -40px;
    margin-top: -115px; }
  footer .logo img {
    width: 40%;
    margin: 0 auto;
    text-align: center;
    display: block; }
  footer .f_text {
    padding-left: 20px;
    text-align: center;
    padding-bottom: 10px; }
  footer .orange_logo {
    position: absolute;
    padding-top: 85px;
    background-color: #191a1f;
    width: 100%;
    padding-bottom: 10px;
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0; }
  footer .f_back {
    padding-right: 20px;
    padding-left: 20px;
    margin-top: -10px;
    float: left;
    width: 100%;
    text-align: left;
    .phone_numb {
      a:first-child {
        text-align: center; } } }
  footer .f_back .phone_button a {
    color: #fff;
    text-align: center;
    width: 45%;
    margin: 0 auto; }
  #back_call_modal .modal-content, #prob_modal .modal-content, #buypomad_modal .modal-content {
    background-color: #c66376;
    padding: 0;
    margin: 0 auto;
    width: 100%;
    background-image: none;
    .modal-body {
      position: relative;
      padding: 20px 0 20px 50px; } }
  .modal-dialog {
    margin: 0; }
  #pomad_modal .modal-content {
    background-image: none;
    min-height: auto;
    padding: 10px;
    background-color: #f7f6ef; }
  #img_pomad {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 35%;
    margin-bottom: 25px; }
  .modal-body .title {
    font-size: 24px; } }
@media (width: 1024px) {
  #content3 .more_colors {
    padding: 0;
    width: 100%;
    max-width: 200px; }
  #content3 #block_two .more_colors {
    width: 100%;
    float: none;
    max-width: 200px;
    margin: 0;
    padding-left: 5%; }
  #content3 #block_four .more_colors {
    padding: 0 0 0 0;
    width: 100%;
    margin: 0;
    max-width: 200px;
    margin: 0px 45px; } }
@media (width: 736px) {
  #content2 .col-md-3 {
    width: 50%;
    float: left; }
  #content3 .owl-item #block_one .col-md-4 {
    width: 33.3%;
    float: left;
    margin: 0 auto; }
  #content3 .owl-item #block_two .col-md-6 {
    width: 50%;
    float: left;
    margin: 0 auto; }
  #content3 .owl-item #block_four .col-md-4 {
    width: 33.3% !important;
    float: left !important;
    padding: 0 10px !important;
    margin: 0 auto !important; }
  #content3 .owl-item #block_two .img.col-md-12 img {
    width: 70%;
    clear: right;
    margin-left: 0; }
  #content3 .owl-item #block_three .col-md-3 {
    float: left;
    width: 50%;
    margin-bottom: 20px;
    padding: 0 10%; }
  #content3 .owl-carousel .owl-item #block_three img {
    width: 40%; }
  #content3 .owl-carousel .owl-item div#block_four {
    padding: 0 5%; }
  #content3 #block_four .b3_title {
    font-size: 20px; }
  #content3 #block_four .color_descr {
    font-size: 10px; }
  #content3 #block_four a.more_info {
    margin-left: 0%;
    font-size: 12px;
    width: 100%; }
  .clean {
    height: 28px; }
  #content4 .col-md-6 {
    position: relative;
    width: 50%;
    float: left; }
  #content4 .col-md-6 img.blc_img1 {
    width: 100%;
    margin-left: 0;
    margin-top: 0; }
  #content4 .title {
    margin-bottom: 30px; }
  #content4 .cnt4_block2 {
    margin-top: 45px; }
  #content4 .cnt4_block2 img.blc_img2 {
    margin-top: -25px; }
  #content4 .row.cnt4_block3 {
    margin-top: 50px; }
  #content4 .row.cnt4_block4 {
    margin-top: 0px; }
  #content4 .cnt4_block4 {
    margin-top: 30px; }
  #content4 .cnt4_block4 img.blc_img4 {
    margin-left: 0;
    margin-top: 75px;
    width: 90%; }
  #content4 a.btn_style {
    max-width: 90%;
    margin-bottom: 0;
    display: block;
    z-index: 82;
    position: relative;
    margin: 0 auto;
    margin-bottom: 175px;
    float: left;
    margin-top: 40px;
    margin-left: 10%; }
  #content5 .col-md-5.col-md-offset-1 {
    margin-top: 0;
    padding-left: 30px; }
  #content6 .cnt6_blocks .col-md-6 {
    padding: 0 20px;
    width: 50%;
    margin: 0 auto;
    float: left; }
  #content6 .image_cnt6 {
    position: relative;
    margin-top: 35px; }
  #content7 {
    background-position: 0 0;
    padding-top: 85px;
    margin-top: 60px;
    margin-bottom: -15px;
    background-position: 55% 0; }
  #content7 .form_block {
    margin-top: 50px;
    padding-right: 0;
    margin-bottom: 120px;
    width: 50%;
    float: right; }
  footer .logo {
    width: 30%;
    float: left;
    img {
      width: 100%; } }
  footer .orange_logo {
    background-color: transparent; }
  footer .f_text {
    width: 45%;
    float: left;
    text-align: left;
    margin-top: 5px; }
  footer .f_back {
    width: 30%;
    float: right;
    margin-top: -130px; }
  footer .f_back .phone_button a {
    width: 100%; }
  footer .orange_logo {
    float: left;
    position: relative;
    background-color: transparent;
    padding-top: 0; }
  #back_call_modal .modal-content .modal-body, #prob_modal .modal-content .modal-body, #buypomad_modal .modal-content .modal-body {
    position: relative;
    padding: 0px 10% 30px 24%; }
  #back_call_modal .modal-content, #prob_modal .modal-content, #buypomad_modal .modal-content {
    width: 70%; }
  #pomad_modal .modal-dialog {
    width: 70%;
    max-width: 985px;
    margin: 0 auto !important; } }
